import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <div className="controller-header">
                        <p className="header"><FontAwesomeIcon className={"fa-fw index unselectable"} icon={"arrow-right-from-bracket"} /> Estamos em casa: </p>
                    </div>
                <ul id="progress-bar" className="entry-bookend-progress-container header">

                </ul>
            </>
        )
    }
}
export default Home;