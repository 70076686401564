import AsyncStorage from "@react-native-async-storage/async-storage";

export default {
    getBoolKeyFromStorage: async (key) => {
        try{
            return (await AsyncStorage.getItem(key) === "true");
        } catch(ex){
            //console.log(ex);
            return false;
        }
    },
    getKeyFromStorage: async (key) => {
        try{
            return await AsyncStorage.getItem(key);
        } catch(ex){
            //console.log(ex);
            return false;
        }
    },
    setKeyInStorage: async (key, valor) => {
        try{
            return await AsyncStorage.setItem(key, valor);
        } catch(ex){
            console.log(ex);
            return false;
        }
    }
}