import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import Modal from '../components/ui/modal';
import {QRCodeCanvas} from 'qrcode.react';
import moment from "moment";

import { toast } from 'react-toastify';

class ContratoSelecionado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pixVisivel: false,
            pixQrCode: 'QRcode Gerado Inválido.',
            identificador: 0
        }
    }

    async selecionarFatura(fatura){
        this.props.onSuccess && this.props.onSuccess({fatura});
    }

    async cancelarTransacao() {
        const retornoTransacao = await ApiService.cancelarTransacao({
            identificador: this.state.identificador,
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
        });
        if (retornoTransacao?.id){   
            toast.success("Transacao cancelada com sucesso.", {
                theme: "colored"
            });
        } else {
            toast.error("Falha ao Processar Cancelamento. Contate o Administrador de Sistemas.",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }


    async pagarCartaoDebito(fatura){
        const identificador = moment().valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.debito',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador
        }, () => this.cancelarTransacao() );
        if (retornoTransacao?.status){   
            toast.success("Documento Processado com sucesso", {
                theme: "colored"
            });
        } else {
            toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas.",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }

    async pagarCartaoCredito(fatura){
        const identificador = moment.valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.credito',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador
        }, () => this.cancelarTransacao());
        if (retornoTransacao?.status){
            toast.success("Documento Processado com sucesso");
        } else {
            toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas.",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }

    async pagarPix(fatura){
        const identificador = moment.valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.pix',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador
        }, () => this.cancelarTransacao());
        if (retornoTransacao?.status && retornoTransacao?.retorno?.detalhe?.pixPagamento){
            return this.setState({
                pixVisivel: true,
                pixQrCode: retornoTransacao?.retorno?.detalhe?.pixPagamento
            });
        }
        toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas.",{
            autoClose: false,
                position: "bottom-center",
                theme: "colored",
                closeOnClick: true,
        });
    }

    async detalhe(fatura){
        window.open(fatura.urlpdf, "_blank");
    }

    render() {
        return (            
            <>
            <div className="controller-header">
                        <p className="header"><FontAwesomeIcon className={"fa-fw index unselectable"} icon={"arrow-right-from-bracket"} /> Faturas do Contrato: </p>
                    </div>
                    <ul id="progress-bar" className="entry-bookend-progress-container header">
                {
                    this.props.contrato?.faturasPendentes?.filter((contrato) => {
                        return true
                    }).map(fatura => {
                        return (
                            <>
                                <li className="progress-item progress-step resultado fatura-card" style={{ flex: 2}}  onClick={()=> this.selecionarFatura(fatura) }>
                                    <span className="circle progress-index larguinho">
                                        <p style={{margin: 5}}>
                                            <span className="index elipsis"><FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"calendar-day"}  />  Vencimento: { fatura?.datavencimentoparse } </span>
                                        </p>
                                        <p style={{margin: 5}}>
                                            <span className="index elipsis"><FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"sack-dollar"}  />  Valor: { fatura?.valordocumentoparse } </span>
                                        </p>
                                        <p style={{margin: 5}}>
                                            <span className="index elipsis"><FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"receipt"}  /> Documento: { fatura?.numerodocumento } </span>
                                        </p>
                                        <p style={{margin: 5}}>
                                            <span className="index elipsis"><FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"business-time"}  /> Situação: [{fatura?.situacaofatura}] </span>
                                        </p>
                                    </span>
                                </li>
                                <li className="progress-item progress-step resultado fatura-card" >
                                    <span className="circle progress-index larguinho" style={{margin: 1, cursor: "pointer"}}  onClick={()=> this.pagarPix(fatura) }>
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"qrcode"}  /> Pagar com Pix
                                    </span>
                                    <span className="circle progress-index larguinho" style={{margin: 1, cursor: "pointer"}}  onClick={()=> this.detalhe(fatura) }>
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"circle-info"}  /> Detalhes
                                    </span>
                                
                                    <span className="circle progress-index larguinho" style={{margin: 1, cursor: "pointer"}} onClick={()=> this.pagarCartaoDebito(fatura)}>
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"credit-card"}  /> Cartão Débito
                                    </span>
                                    <span className="circle progress-index larguinho" style={{margin: 1, cursor: "pointer"}} onClick={()=> this.pagarCartaoCredito(fatura)}>
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"credit-card"}  /> Cartão Crédito
                                    </span>
                                </li>
                                <li className='break'></li>
                            </>
                        )
                    })
                }
                </ul>
                <Modal visible={this.state.pixVisivel} icon={"qrcode"} title={"Pix para Pagamento"} onClose={() => this.setState({ pixVisivel: false }) }>
                    Realize o pagamento no seu internet banking, fazendo a leitura do QRCODE abaixo. Em até 15 minutos, seu pagamentos será reconhecido e o seu contrato re-estabelecido.
                    <QRCodeCanvas value={this.state.pixQrCode} style={{padding: 10 }} />  
                </Modal>
            </>
        )
    }
}
export default ContratoSelecionado;