import { Outlet, Link } from "react-router-dom";
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import IconeHay from "../images/logo-192.png";
import ApiService from "../service/ApiService";
import '../App.css';
import UtilService from "../service/UtilService";

import { toast } from 'react-toastify';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contrato: {}
        }
    }   
    render() {
        return (
            <>
                <div className="test-container">
                    <div className="controller-header">
                        <p className="header"><FontAwesomeIcon className={"fa-fw index"} icon={"arrow-right-from-bracket"} /> Atendimento ao Cliente: </p>
                    </div>
                    <ul className="entry-bookend-progress-container header">
                        <img alt='Logo da HaYSystems' src={IconeHay} className="hay-logo" />
                        <div>
                            <h2>Auto Atendimento HaY Systems</h2>
                            <h4>Seja bem vindo ao Sistema de auto-atendimento baseado em toque.</h4>
                        </div>
                    </ul>
                    <div className="controller-header">
                        <p className="header"><FontAwesomeIcon className={"fa-fw index"} icon={"arrow-right-from-bracket"} /> Funcionalidades Disponiveis: </p>
                    </div>
                    <ul id="progress-bar" className="entry-bookend-progress-container header">
                        <Link to="/" style={{ flex: 1}} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar,toast)}>
                            <li className="progress-item progress-step" title={"Voltar Ao Inicio"}>
                                <span className="circle progress-index" >
                                    <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={"house-circle-check"} />
                                    <p className="subtitulo-acoes unselectable">Home</p>
                                </span>
                            </li>
                        </Link>
                        <Link to="/identificacao" style={{ flex: 1}} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar, toast)}>
                            <li className="progress-item progress-step" title={"Voltar Ao Inicio"}>
                                <span className="circle progress-index" >

                                    <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={"user-check"} />
                                    <p className="subtitulo-acoes unselectable">Identificacao</p>

                                </span>
                            </li>
                        </Link>
                        <Link to="/selecionarcontrato" style={{ ...{flex: 1}, ...UtilService.reactEnabled(this.props.contrato?.contratoid)}} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar,toast)}>
                            <li className="progress-item progress-step" title={"Voltar Ao Inicio"}>
                                <span className="circle progress-index" >

                                    <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={"address-card"} />
                                    <p className="subtitulo-acoes unselectable">Contrato</p>

                                </span>
                            </li>
                        </Link>
                        <Link to="/selecionarcontrato" style={{ ...{flex: 1}, ...UtilService.reactEnabled(this.props.contrato?.contratoid)}} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar,toast)}>
                            <li className="progress-item progress-step" title={"Voltar Ao Inicio"}>
                                <span className="circle progress-index" >
                                        <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={"receipt"} />
                                        <p className="subtitulo-acoes unselectable">Faturas</p>
                                </span>
                            </li>
                        </Link>

                        <Link to="/falecomigo" style={{...{ flex: 1}, ...UtilService.reactShow(this.props.operacaoTotem?.falecomigo), ...UtilService.reactEnabled(this.props.contrato?.contratoid) }} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar,toast)} >
                            <li className="progress-item progress-step" title={"Fale Comigo"}>
                                <span className="circle progress-index" >
                                        <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={faWhatsapp} />
                                        <p className="subtitulo-acoes unselectable">Fale Comigo</p>
                                </span>
                            </li>
                        </Link>
                        <Link to="/meligue" style={{ ...{flex: 1}, ...UtilService.reactShow(this.props.operacaoTotem?.meligue), ...UtilService.reactEnabled(this.props.contrato?.contratoid)}} onClick={()=> this.props.contrato?.contratoid && UtilService.iniciarTimerSessao(this.props.aoExpirar,toast)} >
                            <li className="progress-item progress-step" title={"Me liga"}>
                                <span className="circle progress-index" >
                                    <FontAwesomeIcon className={"fa-fw index menu unselectable"} size="4x" icon={"fa-phone-volume"} />
                                    <p className="subtitulo-acoes unselectable">Me Liga</p>
                                </span>
                            </li>
                        </Link>
                    </ul>
                    <Outlet /> 
                </div>

            </>
        )
    }
}
export default Layout;