import './App.css';
import React, { Component } from 'react';

import IconeHay from "./images/logo-192.png";

import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Identificacao from "./pages/Identificacao";
import ContratoSelecionado from "./pages/ContratoSelecionado";
import SelecionarContrato from "./pages/SelecionarContrato";
import PaginaNaoExiste from './pages/PaginaNaoExiste';

import { library as FontAwesomeLib } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/ui/loader';
import UtilService from './service/UtilService';
import ApiService from './service/ApiService';
 
import 'animate.css/animate.css'
import FaleComigo from './pages/FaleComigo';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class App extends Component {
    constructor(props) {
        FontAwesomeLib.add(fab, fas);
        super(props);
        this.state = {}
    }    
    async componentDidMount() {
        const idOperacaoTotem = UtilService.getUrlParams("idOperacaoTotem") 
        if (idOperacaoTotem){
            const operacaoTotem = await ApiService.validarParametrosTotem(idOperacaoTotem);
            this.setState({ operacaoTotem });
        } else {
            toast.error("Identificação de Totem Ausente",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored"
            });
        }
    }

    aoExpirar (){
        this.setState({ contrato: null });
    }

    render() {
        return (

            <>

                <Helmet>

                    <title>{'HaY Auto-Atendimento'}</title>
                    <meta name="description" content={'HaY Auto-Atendimento'} />
                    <link class="icone-app" rel="apple-touch-icon" href={IconeHay} />
                    <link class="icone-app" rel="icon" href={IconeHay } />
                </Helmet>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()}  />}>
                        <Route index element={<Home aoExpirar={()=> this.setState({ contrato: null })}/>} />
                        <Route path="identificacao" element={<Identificacao operacaoTotem={this.state.operacaoTotem} onSuccess={(contratos) => this.setState({ contratos })} onSucessUmContrato={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()}/>} />
                        <Route path="selecionarcontrato" element={<SelecionarContrato contratos={this.state.contratos} operacaoTotem={this.state.operacaoTotem} onSuccess={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()} />} />
                        <Route path="contratoselecionado" element={<ContratoSelecionado contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()}/>} />
                        <Route path="falecomigo" element={<FaleComigo contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()} />} />
                        <Route path="*" element={<PaginaNaoExiste />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                <Loader />
                <ToastContainer />
            </>
        )
    }
}
export default App;
