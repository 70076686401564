import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';

class FaleComigo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            telefone: '14996176187'
        }
    }
    pressionar(numero) {
        this.setState({ telefone: this.state.telefone + String(numero) });
    }
    apagar() {
        if (this.state.telefone) {
            this.setState({ telefone: this.state.telefone.slice(0, -1) });
        }
    }
    async iniciarAtendimento() {
        const atendimento = await ApiService.iniciarAtendimento({
            idclientecontrato: this.props.contrato?.contratoid,
            idOperacaoTotem: this.props.operacaoTotem?.id,
            telefone: this.state.telefone
        });
        if (atendimento?.enviado){
            toast.success("Uma mensagem foi enviada para seu dispositivo, para iniciar um atendimento.", {
                theme: "colored"
            });
        }
        else {
            toast.error("Não foi possivel enviar uma mensagem para seu dispositivo. Entre em contato com o nosso suporte.",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored",
            });
        }
    }
    render() {
        return (
            <>
                <div className="controller-header">
                    <p className="header"><FontAwesomeIcon className={"fa-fw index unselectable"} icon={"arrow-right-from-bracket"} /> Digite o seu numero de telefone, com DDD: </p>
                </div>
                <span className="entry-bookend-progress-container header" style={{paddingBottom: 0}}>{this.state.telefone}</span>
                <ul id="progress-bar" className="entry-bookend-progress-container header">                    
                    <li className="progress-item progress-step" title={"Pressionar 1"} onClick={() => this.pressionar(1)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 1 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 2"} onClick={() => this.pressionar(2)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 2 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 3"} onClick={() => this.pressionar(3)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 3 </span>
                            </span>
                        </span>
                    </li>
                    <li className='break'></li>
                    <li className="progress-item progress-step" title={"Pressionar 4"} onClick={() => this.pressionar(4)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 4 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 5"} onClick={() => this.pressionar(5)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 5 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 6"} onClick={() => this.pressionar(6)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 6 </span>
                            </span>
                        </span>
                    </li>
                    <li className='break'></li>
                    <li className="progress-item progress-step" title={"Pressionar 7"} onClick={() => this.pressionar(7)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 7 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 8"} onClick={() => this.pressionar(8)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 8 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 9"} onClick={() => this.pressionar(9)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 9 </span>
                            </span>
                        </span>
                    </li>
                    <li className='break'></li>
                    <li className="progress-item progress-step" title={"Apagar"} onClick={() => this.apagar()}>

                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="2x" icon={"circle"} transform="right-3" style={{ color: "red" }} />
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"delete-left"} />
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Pressionar 0"} onClick={() => this.pressionar(0)}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} />
                                <span class="fa-layers-text fa-inverse fa-3x unselectable" style={{ fontWeight: 900 }}> 0 </span>
                            </span>
                        </span>
                    </li>
                    <li className="progress-item progress-step" title={"Buscar Contrato"} onClick={() => this.iniciarAtendimento()}>
                        <span className="circle progress-index numero" >
                            <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle"} style={{ color: "lime" }} />
                                <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"circle-check"} />
                            </span>
                        </span>
                    </li>
                </ul>
            </>
        )
    }
}
export default withRouter(FaleComigo);