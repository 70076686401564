import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';

class SelecionarContrato extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async selecionarContrato(contrato) {
        const faturas = await ApiService.buscarFaturasPorContrato({ idcontrato: contrato.contratoid }) || [];
        this.props.onSuccess && this.props.onSuccess({ ...contrato, ...faturas });
        return this.props.navigate("/contratoselecionado");
    }

    render() {
        return (
            <>
                <div className="controller-header">
                    <p className="header"><FontAwesomeIcon className={"fa-fw index unselectable"} icon={"arrow-right-from-bracket"} /> Seleção de Contrato: </p>
                </div>
                <ul id="progress-bar" className="entry-bookend-progress-container header">
                    {
                        this.props.contratos?.filter((contrato) => {
                            return true
                        }).map(contrato => {
                            return (
                                <>
                                    <li className="progress-item progress-step resultado fa" style={{ width: "100%" }} onClick={() => this.selecionarContrato(contrato)}>
                                        <span className="circle progress-index larguinho">
                                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" title={contrato?.situacao} icon={"user"} />
                                            <span className="index elipsis"> Assinante: {contrato?.nomeassinante} </span>
                                            <p className='linha-contrato'>Endereço: {contrato?.endereco}</p>
                                            <p className='linha-contrato'>Situacao: {contrato?.situacao} IdAssinante: [{contrato?.idassinante}] Contrato: [{contrato?.idassinante}] </p>
                                        </span>
                                    </li>
                                    <li className='break'></li>
                                </>
                            )
                        })
                    }
                </ul>
            </>
        )
    }
}
export default withRouter(SelecionarContrato);